

#page3 {
padding: 140px 0;
/* background-image: url(../images/anchor.png); */
height:100vh;
/* min-height: 100%; */
position: relative !important;
background-size: cover !important;
background-position: center !important;
background-repeat: no-repeat !important;
/* height: 100%; */
width: 100%;
}
.swiper-pagination {
 position: absolute;
 /* top: 200px; */
bottom:60px;
 right: 100px;
/* background:#fff; */
border-radius: 5px;;
 width: auto !important;
 left: auto !important;
 margin: 0;
}
.swiper-pagination-bullet {
 padding: 5px 5px;
 /* border-radius:0; */
 width: auto;
 /* height: 30px; */
 text-align: center;
 /* line-height: 30px; */
 font-size: 12px;
 color:#000;
 opacity: 1;
 background: rgba(0,0,0,0.2);
}
.wsk-cp-img .swiper-pagination-bullet-active {
 color:#fff;
 background: #eb6e1d;
}
.shell{
 padding:80px 0;
}
.wsk-cp-product{
 background:#fff;
 /* padding:15px; */
 border-radius:6px;
 box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
 position:relative;
 margin:20px auto;
 height:250px;
}
.wsk-cp-img{
 position:absolute;
 top:5px;
 left:50%;
 transform:translate(-50%);
 -webkit-transform:translate(-50%);
 -ms-transform:translate(-50%);
 -moz-transform:translate(-50%);
 -o-transform:translate(-50%);
 -khtml-transform:translate(-50%);
 width: 100%;
 /* padding: 15px; */
 height:250px;
 transition: all 0.2s ease-in-out;
}
.wsk-cp-img img{
 width:100%;
 transition: all 0.2s ease-in-out;
 border-radius:6px;
 height:100%
}
/* .wsk-cp-product:hover .wsk-cp-img{
 top:-40px;
} */
/* .wsk-cp-product:hover .wsk-cp-img img{
 box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
} */
.wsk-cp-text{
 padding:10px 0 40px 0;
}
.wsk-cp-text .category{
 text-align:center;
 font-size:12px;
 font-weight:bold;
 padding:5px;
 margin-bottom:45px;
 position:relative;
 transition: all 0.2s ease-in-out;
}
/* .wsk-cp-text .category > *{
 position:absolute;
 top:50%;
 left:50%;
 transform: translate(-50%,-50%);
 -webkit-transform: translate(-50%,-50%);
 -moz-transform: translate(-50%,-50%);
 -ms-transform: translate(-50%,-50%);
 -o-transform: translate(-50%,-50%);
 -khtml-transform: translate(-50%,-50%);
   
} */
.wsk-cp-text .category > span{
 padding: 12px 30px;
 border: 1px solid #313131;

 background:#fff;
 color:#313131;
 box-shadow: 0 15px 28px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.10);
 border-radius:27px;
 transition: all 0.05s ease-in-out;
 width:150px;
 height:50px;
 
}
/* .wsk-cp-product:hover .wsk-cp-text .category > span{
 border-color:#ddd;
 box-shadow: none;
 padding: 11px 28px;
}
.wsk-cp-product:hover .wsk-cp-text .category{
 margin-top: 0px;
} */
.wsk-cp-text .title-product{
  display:flex;
  justify-content: center;
  align-items: center;
 /* text-align:left; */
 
}
.wsk-cp-text .title-product a{
 text-align:right;
 text-decoration: none;
 color:#fff;
 font-size:16px;
 border-radius: 3px;
 width: 40%;
 text-transform: capitalize;
 /* height: 30px; */
 padding:5px 7px;
 background-color: #eb6e1d;
 /* border: 1px solid #eb6e1d; */
 
}
.wsk-cp-text .title-product h3{
 font-size:18px;
 font-weight:bold;
 margin:15px auto;
 overflow: hidden;
   white-space: break-spaces;
   /* text-overflow: ellipsis; */
 /* width:100%; */
}
.wsk-cp-text .description-prod p{
 margin:0;
}
/* Truncate */
.wsk-cp-text .description-prod {
 text-align:center;
 width: 100%;
 height:62px;
 overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;
 margin-bottom:15px;
}
.card-footer{
 padding: 25px 0 5px;
 border-top: 1px solid #ddd;
}
.card-footer:after, .card-footer:before{
 content:'';
 display:table;
}
.card-footer:after{
 clear:both;
}

.card-footer .wcf-left{
 float:left;
 
}

.card-footer .wcf-right{
 float:right;
}

.price{
 font-size:18px;
 font-weight:bold;
}

a.buy-btn{
 display:block;
 color:#212121;
 text-align:center;
 font-size: 18px;
 width:35px;
 height:35px;
 line-height:35px;
 border-radius:50%;
 border:1px solid #212121;
 transition: all 0.2s ease-in-out;
}
/* a.buy-btn:hover , a.buy-btn:active, a.buy-btn:focus{
 border-color: #FF9800;
 background: #FF9800;
 color: #fff;
 text-decoration:none;
} */
.wsk-btn{
 display:inline-block;
 color:#212121;
 text-align:center;
 font-size: 18px;
 transition: all 0.2s ease-in-out;
 border-color: #FF9800;
 background: #FF9800;
 padding:12px 30px;
 border-radius:27px;
 margin: 0 5px;
}
/* .wsk-btn:hover, .wsk-btn:focus, .wsk-btn:active{
 text-decoration:none;
 color:#fff;
}   */
.red{
 color:#F44336;
 font-size:22px;
 display:inline-block;
 margin: 0 5px;
}


.product-section-para p {
  margin-left: 65vh;
  text-transform: uppercase;
  margin-top: -256px;
  width: 80%;
  max-width:580px;
  font-family: var(---FontThird);
  letter-spacing: 1px;
  color: whitesmoke;
}
@media (min-width: 150px) and (max-width: 699px) {
  .Service-Section-Text h1 {
    font-size: 1.4rem;
    margin: 10px 6px;
    text-align: center;
    color: whitesmoke;
  }
  .Service-Section-Text p {
    font-size: 0.8rem;
    max-width: 660px;
    margin: 10px 10px;
  }

}
@media(max-width:1024px) and (min-widh:750px){
 .wsk-cp-product{
   background:#fff;
   /* padding:15px; */
   border-radius:6px;
   box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
   position:relative;
   margin:20px auto;
   height:390px;
 }
 .wsk-cp-img{
   position:absolute;
   top:5px;
   left:50%;
   transform:translate(-50%);
   -webkit-transform:translate(-50%);
   -ms-transform:translate(-50%);
   -moz-transform:translate(-50%);
   -o-transform:translate(-50%);
   -khtml-transform:translate(-50%);
   width: 100%;
   /* padding: 15px; */
   height:250px;
   transition: all 0.2s ease-in-out;
 }
 .wsk-cp-img img{
   width:100%;
   transition: all 0.2s ease-in-out;
   border-radius:6px;
   height:100%
 } .wsk-cp-product{
 background:#fff;
 /* padding:15px; */
 border-radius:6px;
 box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
 position:relative;
 margin:20px auto;
 height:390px;
}
.wsk-cp-img{
 position:absolute;
 top:5px;
 left:50%;
 transform:translate(-50%);
 -webkit-transform:translate(-50%);
 -ms-transform:translate(-50%);
 -moz-transform:translate(-50%);
 -o-transform:translate(-50%);
 -khtml-transform:translate(-50%);
 width: 100%;
 /* padding: 15px; */
 height:250px;
 transition: all 0.2s ease-in-out;
}
.wsk-cp-img img{
 width:100%;
 transition: all 0.2s ease-in-out;
 border-radius:6px;
 height:100%
}
}
@media(max-width:751px) and (min-width:620px){
 .wsk-cp-product{
   background:#fff;
   /* padding:15px; */
   border-radius:6px;
   box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
   position:relative;
   margin:20px auto;
   height:390px;
 }
 .wsk-cp-text{
   padding-top:70%;
 }
 .wsk-cp-img{
   position:absolute;
   top:5px;
   left:50%;
   transform:translate(-50%);
   -webkit-transform:translate(-50%);
   -ms-transform:translate(-50%);
   -moz-transform:translate(-50%);
   -o-transform:translate(-50%);
   -khtml-transform:translate(-50%);
   width: 100%;
   /* padding: 15px; */
   height:300px;
   transition: all 0.2s ease-in-out;
 }
 .wsk-cp-img img{
   width:100%;
   transition: all 0.2s ease-in-out;
   border-radius:6px;
   height:100%
 } .wsk-cp-product{
 background:#fff;
 /* padding:15px; */
 border-radius:6px;
 box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
 position:relative;
 margin:20px auto;
 height:390px;
}
.wsk-cp-img{
 position:absolute;
 top:5px;
 left:50%;
 transform:translate(-50%);
 -webkit-transform:translate(-50%);
 -ms-transform:translate(-50%);
 -moz-transform:translate(-50%);
 -o-transform:translate(-50%);
 -khtml-transform:translate(-50%);
 width: 100%;
 /* padding: 15px; */
 height:250px;
 transition: all 0.2s ease-in-out;
}
.wsk-cp-img img{
 width:100%;
 transition: all 0.2s ease-in-out;
 border-radius:6px;
 height:100%
}
}
@media(max-width:621px) and (min-width:538px){
 .wsk-cp-product{
   background:#fff;
   /* padding:15px; */
   border-radius:6px;
   box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
   position:relative;
   margin:20px auto;
   height:390px;
 }
 .wsk-cp-text{
   padding-top:70%;
 }
 .wsk-cp-img{
   position:absolute;
   top:5px;
   left:50%;
   transform:translate(-50%);
   -webkit-transform:translate(-50%);
   -ms-transform:translate(-50%);
   -moz-transform:translate(-50%);
   -o-transform:translate(-50%);
   -khtml-transform:translate(-50%);
   width: 100%;
   /* padding: 15px; */
   height:300px;
   transition: all 0.2s ease-in-out;
 }
 .wsk-cp-img img{
   width:100%;
   transition: all 0.2s ease-in-out;
   border-radius:6px;
   height:100%
 } .wsk-cp-product{
 background:#fff;
 /* padding:15px; */
 border-radius:6px;
 box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
 position:relative;
 margin:20px auto;
 height:390px;
}
.wsk-cp-img{
 position:absolute;
 top:5px;
 left:50%;
 transform:translate(-50%);
 -webkit-transform:translate(-50%);
 -ms-transform:translate(-50%);
 -moz-transform:translate(-50%);
 -o-transform:translate(-50%);
 -khtml-transform:translate(-50%);
 width: 100%;
 /* padding: 15px; */
 height:250px;
 transition: all 0.2s ease-in-out;
}
.wsk-cp-img img{
 width:100%;
 transition: all 0.2s ease-in-out;
 border-radius:6px;
 height:100%
}
}
@media(max-width:538px) and (min-width:300px){
 .wsk-cp-product{
   background:#fff;
   /* padding:15px; */
   border-radius:6px;
   box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
   position:relative;
   margin:20px auto;
   height:350px;
 }
 .wsk-cp-text{
   padding-top:70%;
   padding-bottom:30px;
 }
 .wsk-cp-img{
   position:absolute;
   top:5px;
   left:50%;
   transform:translate(-50%);
   -webkit-transform:translate(-50%);
   -ms-transform:translate(-50%);
   -moz-transform:translate(-50%);
   -o-transform:translate(-50%);
   -khtml-transform:translate(-50%);
   width: 100%;
   /* padding: 15px; */
   height:270px;
   transition: all 0.2s ease-in-out;
 }
 .wsk-cp-img img{
   width:100%;
   transition: all 0.2s ease-in-out;
   border-radius:6px;
   height:100%
 } .wsk-cp-product{
 background:#fff;
 /* padding:15px; */
 border-radius:6px;
 box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
 position:relative;
 margin:20px auto;
 height:auto;
}

/* @media (max-width: 390px) and (min-width: 30px) {
 .wsk-cp-text .title-product h3 {
  margin-top: 70px;
 }
} */

/* .wsk-cp-img img{
 width:100%;
 transition: all 0.2s ease-in-out;
 border-radius:6px;
 height:100%
} */
}
.search-box
{
 display:flex;
 /* justify-content: center;
 align-items: center; */
 /* flex-direction: column; */
}

.search-box input
{
  border: 1px solid #eb6e1d;
  width: 300px;
  border-radius: 50px;
  padding: 0 20px;
  height: 40px;
}
.search_btn
{
 width:60px;
 height:50px;
 background:#7b7c7e;
 color:#fff;
 border:none;
}
.search_btn i
{
 font-size:22px;
 font-weight:800;

}
.count h2{
  border: 1px solid #eb6e1d;
  font-size: 14px;
  padding: 9px;
  /* height: 50px; */
  border-radius: 50px;
  /* padding: 0 20px; */
  height: 40px;
}
.count input{
 width:200px;
 margin-left:10px;
}
.formTitleSection
{
 display:flex;
 justify-content: end;
}
.closeProgram1 
{
 width:30px;
 height:30px;
display:flex;
justify-content:center;
align-items:center;
 
 border-radius:5px;
 background:#c0c0c0;
 
}
.closeProgram1 i{
 display:flex;
justify-content:center;
align-items:center;
 font-size:20px;
 color:#fff;
}
.projSwiper
{ height:200px;
 min-height:100%;

}
.projSwiper img
{height:100%;
 width:100%;

}


.hexagon {clip-path: polygon(50% 0, 100% 28%, 100% 72%, 50% 100%, 0% 72%, 0% 28%);}
.products_search_type .hexagoninner {
 background: #fecf0c;
 width: 96%;
 height: 96%;
 font-size: 0;
 transform: translate(-50%,-50%);
 z-index: 999;
}
.ButtonG
{
color:#fff;
border:1px solid #fff;

}


@media (min-width: 150px) and (max-width: 699px) {
.count h2{
  /* border: 1px solid blue; */
  margin-top: 20px;
  font-size:16px;
  padding:15px;
  height:40px;
    }
  }