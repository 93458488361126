/* ////////////////////// section 1 ////////////////////////// */

.Contact-Section-1 {
    /* border: 1px solid red; */
    height: 100vh;
    background: linear-gradient(90deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .4)), url("../Images/contactus.jpg");
    /* -webkit-background-size: cover; */
    background-size: 100% 100%;
}
.Contact-Banner {
    height: 91vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

.Contact-Banner h1 {
    margin-left: 10vh;
    text-transform: uppercase;
    font-family: var(---FontThird);
    letter-spacing: 1px;
    color: whitesmoke;
}
.Contact-Banner p{
    max-width: 660px;
    margin: 10px 0;
    font-size: 1rem;
    text-transform: uppercase;
    font-family: var(---FontThird);
    letter-spacing: 1px;
    color: whitesmoke;
}

@media(min-width:500px) and (max-width:900px)
{.Contact-Section-1 {
    /* border: 1px solid red; */
    height: 60vh;
    background: linear-gradient(90deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .4)), url("../Images/contactus.jpg");
    /* -webkit-background-size: cover; */
    background-size: 100% 100%;
}
.Contact-Banner h1 {
    margin-left: 10vh;
    text-transform: uppercase;
    font-family: var(---FontThird);
    letter-spacing: 1px;
    color: whitesmoke;
}
.Contact-Banner p{
    max-width: 660px;
    margin: 110px 0;
    font-size: 1rem;
    text-transform: uppercase;
    font-family: var(---FontThird);
    letter-spacing: 1px;
    color: whitesmoke;
}
}
@media (min-width: 150px) and (max-width: 699px) {
.Contact-Banner p {
    font-size: 0.8rem;
    max-width: 660px;
    margin: 10px 10px;
  }
}


/* //////////////////////// Section 2 /////////////////////////// */

.Contact-Section-2 {
    padding-top: 20vh;
    /* border: 1px solid red; */
}

.Contact-Information {
    /* border: 1px solid blue; */
    height: 100%;
    background-color: #eb6f19;
    border-radius: 5px;
    padding: 30px 15px;
}

.Contact-Information-h3 {
    text-align: left;
    font-family: var(---HeadingFont);
    font-family: var(---SecondFont);
    font-weight: 600;
    margin: 0px 0px 40px 0px;
    color: whitesmoke;
}


.contact-info-con {
    display: flex;
    margin-top: 20px;
}

.Contact-icon {
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 12vh;
    background-color: #000;
    border-radius: 50px; */
}

.Contact-icon-info-con {
    /* border: 1px solid blue; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
}

.Contact-icon svg {
    font-size: 30px;
    margin: 20px;
    color: white;
}

.Contact-icon-info {
    text-align: left;
    /* display: flex; */
}

.Contact-icon-info h3 {
    font-size: 14px;
    font-family: var(---ParaFont);
}

.Contact-icon-info p {
    font-size: 14px;
    font-weight: 600;
    margin: 0px;
    font-family: var(---HeadingFont);
    color: gainsboro;
    letter-spacing: 1px;
}

.Contact-Form-details {
    /* border: 1px solid red; */
    margin: 20px 0px;
    /* box-shadow: 0 0 5px gray; */
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 20px 5px;
}

.Contact-form-text {
    /* border: 1px solid blue; */
    margin-left: 60px;
    text-align: left;
}

.tow-inputs {
    /* border: 1px solid aqua; */
    display: flex;
}

.contactpage-form {
    /* border: 1px solid red; */
    margin-left: 20px;
    margin-left: 40px;
}

.message-input {
    display: flex;
}

/* .message-input label {} */

.message-input textarea {
    padding: 20px 20px 0px 12px;
    width: 100%;
    height: 20vh;
    border: 1px solid grey;
}

/* .contactpage-form form {} */

.contactpage-form label {
    margin: 20px;
    width: 100%;
}

.Message-Text {
    width: 100%;
}

.contactpage-form input {
    padding: 10px 30px 10px 10px;
    width: 100%;
    border: 1px solid grey;
}

.contact-btn {
    /* border: 1px solid red; */
    margin-left: 20px;
    display: flex;
    justify-content: flex-start;
}

.contact-btn button {
    border: 1px solid black;
    width: -moz-fit-content;
    width: fit-content;
    margin: 10px;
    padding: 12px 40px;
    border-radius: 5px;
    background-color: white;
    font-family: var(---HeadingFont);
}

.Contact-form-text h3 {
    text-align: left;
    font-family: var(---SecondFont);
    font-weight: 600;
    margin: 0px 0px 15px 0px;
}

.Contact-form-text p {
    font-size: 14px;
    font-family: var(---ParaFont);
    margin-bottom: 0px;
}

/* ///////////////////////////////////////////////////////////////////////////// */

.ContactUs-map {
    /* border: 1px solid red; */
    padding-top: 10vh;
}

.Contact-iframe {
    height: 60vh;
    box-shadow: 0 0 5px gray;
}

@media(max-width:768px) {
    .Contact-Form-details {
        /* border: 1px solid red; */
        margin-top: 40px;
    }

    .Contact-form-text {
        margin-left: 20px;
    }

    .contactpage-form {
        margin-left: 0px;
    }

    .Contact-Information {
        margin: 20px;
    }
}

@media(max-width:430px) {
    .Contact-Section-1 {
        height: 60vh;
        /* background: url("../Images/slider-bg2\ -\ Copy.jpg"); */
        /* -webkit-background-size: cover; */
    }

    .Contact-Section-2 {
        padding-top: 5vh;
        /* border: 1px solid red; */
    }
    .contact-info-con {
        margin-top: 20px;
    }
}