/* ///////////////////////// Banner ///////////////////////// */

.AboutUS-Banner {
    /* border: 1px solid red; */
    height: 100vh;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, .4)), url("../Images/Banner\ Image\ 2.jpg");
    background-size: cover;
    background-position: center;
    
}
.AboutUS-Bannertext {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    

}
.AboutUS-Bannertext  h2{
    margin-left: 10vh;
    text-transform: uppercase;
    font-family: var(---FontThird);
    letter-spacing: 1px;
    color: whitesmoke;
   
}
.AboutUS-Bannertext p{
    max-width: 660px;
    margin: 10px 0;
    font-size: 1rem;
    text-transform: uppercase;
    font-family: var(---FontThird);
    letter-spacing: 1px;
    color: whitesmoke;
}

@media(min-width:300px) and (max-width:900px)
{
    .AboutUS-Banner {
        /* border: 1px solid red; */
        height: 50vh;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, .4)), url("../Images/Banner\ Image\ 2.jpg");
        background-size: cover;
        background-position: center;
        /* position: absolute; */
    }
    
}



/* /////////////////////////// Section 3 /////////////////////// */

.AboutSection3 {
    /* border: 1px solid red; */
    padding: 10vh 0px;
}

.why-choose-Us-text {
    /* border: 1px solid blue; */
    text-align: left;
}

.why-choose-Us-text h3 {
    margin: 20px 0px;
    padding-bottom: 5px;
    display: inline-block;
    font-family: var(---FontThird);
    font-size: 35px;
    position: relative;
}


.WhyUs-text::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    height: 4px;
    background: var(---TheamColor);
    border-radius: 5px;
    width: 40px;
    transition: all 1.5s;
}



.WhyUs-Logo-Main-Con {
    background-color: whitesmoke;
    padding-bottom: 10px;
}

.About-WhyUS-Logo-con {
    /* border: 1px solid red; */
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.About-WhyUS-Logo {
    /* border: 1px solid seagreen; */
    height: 15vh;
    width: 15vh;
    background-color: var(---TheamColorS);
    border-radius: 50px;
    padding: 15px;
}

.About-WhyUS-Logo img {
    height: 100%;
    width: 100%;
}

.About-WhyUs-Text {
    display: inline-block;
    border: 1px solid red;
    padding: 7px 20px;
    clip-path: polygon(85% 0, 100% 50%, 85% 100%, 0% 100%, 10% 50%, 0% 0%);
    background-color: var(---TheamColorF)
}

.About-WhyUs-Text h4 {
    font-family: var(---FontThird);
    font-size: 20px;
    margin: 10px;
    text-transform: capitalize;
    /* color: var(---TheamColorF); */
    color: white;
}



/* //////////////////////// Section 4 ////////////////////// */

.About-Section4 {
    /* border: 1px solid blue; */
    padding-top: 10vh;
    margin-top: 19vh;
    height: 60vh;
    padding-bottom: 10vh;
    background-color: #eee;
}
.About-Section41 {
    /* border: 1px solid blue; */
    padding-top: 10vh;
    padding-bottom: 10vh;
    background-color: white;
}



.Mission-MainCon {
    /* border: 1px solid red; */
    /* background-color: whitesmoke; */
    padding: 0px;
    /* background: url("../Images/container-docker.jpg");
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, .4)), url("../Images/container-docker.jpg");
    background-size: cover;
    background-position: center; */

}


.Mission-MainCon:hover .Mission-Image-Con {
    animation: rotate-animation 10s infinite linear;
}

@keyframes rotate-animation {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.Misiion-Con {
    border: 1px solid gray;
    padding: 10px;
    position: relative;
}

.MIssion-In-Con {
    border: 1px solid green;
    /* box-shadow: 0 0 5px grey; */
    padding: 13px 21px;
    text-align: left;
    border-radius: 5px;
}

.Mission-plate {
    /* border: 1px solid red; */
    display: inline-block;
    position: absolute;
    top: -15px;
    left: -10px;
    background-color: #eb6e1d ;
}

.Mission-plate::before {
    content: " ";
    position: absolute;
    border: 15px solid transparent;

    border-right-color: #949494;
    transform: rotate(-225deg);
    left: 6.1px;
    top: 27px;
}

.Mission-plate h3 {
    width: 40vh;
    text-align: center;
}
@media(min-width:150px) and (max-width:500px)
{
    .About-Section4 {
        padding-top: 10vh;
        margin-top: 19vh;
        height: auto;
        padding-bottom: 10vh;
        background-color: #eee;
    }
    .Mission-plate h3 {
        width: 20vh;
        font-size: 12px;
        text-align: center;
    }
    .Mission-plate::before {
        content: " ";
        position: absolute;
        border: 15px solid transparent;
        border-right-color: #949494;
        transform: rotate(-225deg);
        left: 6.1px;
        top: 20px;
    }
    .MIssion-In-Con {
      border: 1px solid green;
      /* box-shadow: 0 0 5px grey; */
      padding: 13px 13px;
      text-align: left;
      border-radius: 5px;
  }
  .Mission-MainCon {
    padding: 20px;
}
.why-choose-Us-text h3 {
  font-size: 28px;
}
.About-Section41 {
  /* border: 1px solid blue; */
  padding-top: 0vh;
  padding-bottom: 0vh;
}
.AboutSection3 {
  padding: 0vh 0px;
}

    
}


.MIssion-In-Con p {
    /* margin: 8px 0px 0px 0px; */
    color: black;
}



.Mission-Logo {
    /* border: 1px solid blue; */
    height: 40vh;
    width: 60vh;
    /* margin-left: 15vh; */
    /* background-color: whitesmoke; */
    padding: 5px;
    /* border-radius: 50px; */
}

.Mission-Logo img{
    height: 100%;
    width: 86vh;
}

.Mission-text {
    /* border: 2px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh;
    width: 20vh;
    position: absolute;
    top: 0;
    left: 0;
}

.Mission-text h4 {
    font-family: var(---FontThird);
    font-size: 20px;
}

.Mission-Para {
    /* border: 1px solid red; */
    margin-top: 20px;
}
.About-Section5 {
    /* border: 1px solid blue; */
    padding-top: 10vh;
    padding-bottom: 10vh;
    background-color: #222641;
}


@media(min-width:150px) and (max-width:500px)
{
    .Mission-Logo{
    /* border: 1px solid blue; */
    height: 40vh;
    width: 55vh;
    /* margin-left: 15vh; */
    /* background-color: whitesmoke; */
    padding: 5px;
    /* border-radius: 50px; */
    }
    .Mission-Logo img {
        height: 100%;
        width: 45vh;
    }
}
@media(min-width:665px) and (max-width:1020px)
{
.Mission-Logo img {
    height: 100%;
    width: 65vh;
}
}



.servi_card1{
    border: 1px solid #eb6e1d;
    border-radius: 20px;
    height:31vh;
    padding: 7px;
    margin-bottom: 25px;
    padding: 10px;
    width: 100%;
}

.servi_card-11 {
    /* display: flex; */
    margin: 20px 0px 10px 10px;
}
.servi-card-img1{
    /* max-width: 250px; */
    width: 100%;
    height: 100%;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;

    /* padding: 10px 10px; */
}

.servi-card-img1 img{
    width: 100%;
    max-height: 250px;
    /* justify-content: center; */
    height: 100%;
    object-fit: fill;
}

.serv_card-title1 h5{
    margin: 0;
    /* font-weight: 500; */
    font-size: 18px;
    text-align: left;
    
    letter-spacing: 0.5px;
    font-family: var(---NewFonts);
}
.serv_card-desc1{
text-align: left;
padding-left: 10px;
font-size: 14px;
text-align: left;
line-height: 24px;
color: #666;
/* overflow-y: scroll; */
height: 65px;
transition: all 0.4s ease-out;
}
@media(min-width:768px) and (max-width:1200px)   {
.serv_card-title1 h5{
    margin: 0;
    /* font-weight: 500; */
    font-size: 10px;
    text-align: left;
    letter-spacing: 0.5px;
    font-family: var(---NewFonts);
}
.serv_card-desc1{
text-align: left;
/* padding: 10px; */
font-size: 9px;
text-align: left;
line-height: 24px;
color: #666;
/* overflow-y: scroll; */
height: 65px;
transition: all 0.4s ease-out;
}

}
@media(min-width:300px) and (max-width:575px)   {
.serv_card-title1 h5{
    margin: 0;
    /* font-weight: 500; */
    font-size: 14px;
    text-align: left;
    letter-spacing: 0.5px;
    font-family: var(---NewFonts);
}
.serv_card-desc1{
text-align: left;
/* padding: 10px; */
font-size: 12px;
text-align: left;
line-height: 24px;
color: #666;
/* overflow-y: scroll; */
height: 65px;
transition: all 0.4s ease-out;
}

}


.Whychoose_title{
    text-align: left;
    font-size: 35px;
    text-transform: uppercase;
    font-family: var(---FontThird);
    
}
.Whychoose_title h5{
    font-weight: 600;
}
.Whychoose_desc{
    text-align: left;
}

@media (min-width: 150px) and (max-width: 699px) {
    .AboutUS-Bannertext p {
      font-size: 0.6rem;
      max-width: 660px;
      margin: 10px 10px;
    }
    .AboutUS-Bannertext h2 {
      font-size: 1.4rem;
    margin: 10px 6px;
    text-align: center;
    color: whitesmoke;
  }
    
  }
.countPanel {
    position: relative;
  }
  
  .countingText {
    background: linear-gradient(to right, rgb(0, 0, 0, 0.5), rgb(0, 0, 0, 0.5)),
      url("../Images/growthcounter.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-position: center top;
    height: 65vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 120px;
  }
  
  .countingText h2 {
    color: #fff;
    font-weight: 500;
    font-size: 3rem;
    max-width: 700px;
    text-align: center;
  }
  
  .countingText p {
    color: #eee;
    font-weight: 500;
  
    max-width: 800px;
    text-align: center;
    margin: 0 10px;
  }
  
  .countingText button {
    background-color: #fff;
    color: var(---primeColor);
    font-weight: 500;
    border-radius: 50px;
    /* margin-top: 30px; */
    padding: 8px 20px;
  }
  
  .countingText button:hover {
    color: var(---primeColor);
    background-color: #fff;
  }
  
  .panelCounter {
    width: 100%;
    max-width: 900px;
    background-color: #fff;
    box-shadow: 0 5px 10px #808080;
    border-radius: 5px;
    margin: auto;
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .panelCounter ul {
    width: 100%;
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
  }
  
  .panelCounter ul li {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 30px;
  }
  
  .panelCounter ul li span:nth-child(1) {
    color: var(---primeColor);
    font-size: 3rem;
  }
  
  .panelCounter ul li span.countingClass span {
    color: #111;
    font-size: 1.8rem;
    font-weight: 600;
  }
  
  .panelCounter ul li span:nth-child(3) {
    color: #808080;
    font-size: 1.1rem;
    font-weight: 500;
  }
  
  @media (max-width: 1200px) {
    .countingText h2 {
      font-size: 2rem;
    }
  }
  
  @media (max-width: 990px) {
    .panelCounter {
      max-width: 800px;
    }
  
    .panelCounter ul li {
      padding: 20px 20px;
    }
  }
  
  @media (max-width: 500px) {
    .countingText h2 {
      font-size: 1.6rem;
      text-align: center;
    }
  
    .countingText p {
      color: #eee;
      font-weight: 500;
      max-width: 800px;
      text-align: center;
      line-height: 20px;
      font-size: 0.8rem;
    }
  
    .countingText button {
      background-color: #fff;
      color: var(---primeColor);
      font-weight: 500;
      border-radius: 50px;
      padding: 8px 20px;
      font-size: 0.9rem;
    }
  
    .panelCounter {
      bottom: -120px;
    }
  
    .panelCounter ul {
      flex-wrap: wrap;
      padding: 20px 0;
    }
  
    .panelCounter ul li {
      flex: 1 1 140px;
      padding: 0px 0px;
    }
  
    .panelCounter ul li span:nth-child(1) {
      font-size: 2.5rem;
    }
  
    .panelCounter ul li span.countingClass span {
      color: #111;
      font-size: 1.6rem;
      font-weight: 600;
    }
  
    .panelCounter ul li span:nth-child(3) {
      color: #808080;
      font-size: 0.9rem;
      font-weight: 500;
      text-align: center;
    }
  }
  
.countingText h2 {
  color: #fff;
  font-weight: 500;
  font-size: 3rem;
  max-width: 700px;
  text-align: center;
}

.countingText p {
  color: #eee;
  font-weight: 500;

  max-width: 800px;
  text-align: center;
  margin: 0 10px;
}

.countingText button {
  background-color: #fff;
  color: var(---primeColor);
  font-weight: 500;
  border-radius: 50px;
  /* margin-top: 30px; */
  padding: 8px 20px;
}

.countingText button:hover {
  color: var(---primeColor);
  background-color: #fff;
}


/* /////////////////////////////////// Section 5 /////////////////////////////// */

.About-Section-5 {
    padding: 10vh 0px;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, .4)), url("../Images/Counter\ back.jpg");
    background-size: cover;
    position:relative;
    background-position: center;
}

.AboutUs-Counter-con {
    position: relative;
}

.Counter-Main-Con {
    margin: 25px 15px 25px 15px;
    border-radius: 5px;
    background-color: whitesmoke;
    box-shadow: 0 0 5px gray;
}

.CounterLogo {
    /* border: 1px solid red; */
    position: absolute;
    top: -25px;
    left: 40px;
    height: 10vh;
    width: 10vh;
    background-color: var(---TheamColor);
    border-radius: 5px;
    padding: 10px;
}

.CounterLogo img {
    height: 100%;
    width: 100%;
}

.Counter-Number {
    /* display: flex; */
    height:150px;
    padding: 40px 0px 20px 0px;
    justify-content: center;
    align-items: center;
    font-family: var(---FontThird);
    font-weight: 500;
    font-size: 20px;
}
@media(min-width:570px) and (max-width:1075px)
{
    .Counter-Number {
        display: inherit;
        padding: 40px 0px 20px 0px;
        justify-content: center;
        align-items: center;
        font-family: var(---FontThird);
        font-weight: 500;
        font-size: 20px;
    }
    .CounterLogo {
        /* border: 1px solid red; */
        position: absolute;
        top: -25px;
        left:40%;
        height: 10vh;
        width: 10vh;
        background-color: var(---TheamColor);
        border-radius: 5px;
        padding: 10px;
    }
    
}
@media(min-width:90px) and (max-width:699px)
{
    .Counter-Number {
        display: inherit;
        padding: 40px 0px 20px 0px;
        justify-content: center;
        align-items: center;
        font-family: var(---FontThird);
        font-weight: 500;
        font-size: 20px;
    }
    .CounterLogo {
        /* border: 1px solid red; */
        position: absolute;
        top: -25px;
        left:40%;
        height: 10vh;
        width: 10vh;
        background-color: var(---TheamColor);
        border-radius: 5px;
        padding: 10px;
    }
    
}
.C-number {
    margin: 10px 8px 0px 0px;
    font-size: 30px;
}


.Counter-Number span {
    margin: 10px 8px 0px 0px;

}
.counting-text{
    width:90%;
}
.counting-text h4{

    font-size: 27px;
    color: white;
}
.counting-text p{
    font-size: 15px;
    color: white;
}

.panelCounter {
    width: 100%;
    max-width: 900px;
    background-color: #fff;
    box-shadow: 0 5px 10px #808080;
    border-radius: 5px;
    margin: auto;
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .panelCounter ul {
    width: 100%;
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
  }
  
  .panelCounter ul li {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 30px;
  }
  
  .panelCounter ul li span:nth-child(1) {
    color: var(---primeColor);
    font-size: 1rem;
  }
  
  .panelCounter ul li span.countingClass span {
    color: #111;
    font-size: 1.8rem;
    font-weight: 600;
  }
  
  .panelCounter ul li span:nth-child(3) {
    color: #808080;
    font-size: 1.1rem;
    font-weight: 500;
  }
  
  @media (max-width: 1200px) {
    .countingText h2 {
      font-size: 2rem;
    }
  }
  
  @media (max-width: 990px) {
    .panelCounter {
      max-width: 800px;
    }
  
    .panelCounter ul li {
      padding: 20px 20px;
    }
  }
  
  @media (max-width: 500px) {
    .countingText h2 {
      font-size: 1.6rem;
      text-align: center;
    }
  
    .countingText p {
      color: #eee;
      font-weight: 500;
      max-width: 800px;
      text-align: center;
      line-height: 20px;
      font-size: 0.8rem;
    }
  
    .countingText button {
      background-color: #fff;
      color: var(---primeColor);
      font-weight: 500;
      border-radius: 50px;
      padding: 8px 20px;
      font-size: 0.9rem;
    }
  
    .panelCounter {
      bottom: -120px;
    }
  
    .panelCounter ul {
      flex-wrap: wrap;
      padding: 20px 0;
    }
  
    .panelCounter ul li {
      flex: 1 1 140px;
      padding: 0px 0px;
    }
  
    .panelCounter ul li span:nth-child(1) {
      font-size: 2.5rem;
    }
  
    .panelCounter ul li span.countingClass span {
      color: #111;
      font-size: 1.6rem;
      font-weight: 600;
    }
  
    .panelCounter ul li span:nth-child(3) {
      color: #808080;
      font-size: 0.9rem;
      font-weight: 500;
      text-align: center;
    }
  }
  


