/* ////////////////////////// Section 1 //////////////////////////// */

.Product-Section1 {
    /* border: 1px solid red; */
    height: 100vh;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, .4)), url("../Images/images.jpg");
    background-size: cover;
    background-position: center;
}

.Product-Section-Text {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.Product-Section-Text h1 {
    margin-left: 10vh;
    text-transform: uppercase;
    font-family: var(---FontThird);
    letter-spacing: 1px;
    color: whitesmoke;
}
.Product-Section-Text p{
  max-width: 660px;
  margin: 10px 0;
  font-size: 1rem;
  text-transform: uppercase;
  font-family: var(---FontThird);
  letter-spacing: 1px;
  color: whitesmoke;
}
@media (min-width: 150px) and (max-width: 699px) {
  .Product-Section1 {
    /* border: 1px solid red; */
    height: 60vh;
}
  .Product-Section-Text h1 {
    font-size: 1.4rem;
    margin: 10px 6px;
    text-align: center;
    color: whitesmoke;
  }
  .Product-Section-Text p {
    font-size: 0.8rem;
    max-width: 660px;
    margin: 10px 10px;
  }
}
/* /////////////////////// Section 2 //////////////////////////// */

.Product-Section2 {
    /* border: 1px solid red; */
    padding: 10vh 0px;
}
@media(min-width:570px) and (max-width:699px)
{
    
.Product-Section2 {
    /* border: 1px solid red; */
    padding: 0px;
}
}


.Product-Data hr {
    margin: 40px 0px;
}



.Product-Heading {
    /* border: 1px solid hotpink; */
    text-align: left;
    margin: 20px 0px;
}

.Product-Heading h2 {
    padding-bottom: 5px;
    display: inline-block;
    font-family: var(---FontThird);
    font-size: 35px;
    position: relative;
}

.Product-Heading-Text::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    height: 4px;
    background: var(---TheamColor);
    border-radius: 5px;
    width: 40px;
    transition: all 1.5s;
}



.Sub-Product {
    /* border: 1px solid green; */
    margin: 10px 0px;
    /* border-bottom: 1px solid gray; */
}

.SubProduct-name-con {
    /* border: 1px solid darkblue; */
    text-align: left;
}
.SubProduct-name-con a{
    /* border: 1px solid darkblue; */
    text-decoration:none;
    color:rgb(218, 91, 32);
}

.SubProduct-name-con h4 {
    font-size: 15px;
    font-weight: 400;
    text-align: left;
    text-transform: capitalize;
    padding-bottom: 5px;
    display: inline-block;
    font-family: var(---FontThird);
    /* position: relative; */
}

/* .SubProduct-Name::before{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    height: 1px;
    background: gray;
    border-radius: 5px;
    width: 80%;
    transition: all 1.5s;
} */


/* //////////////////////////////////////// */



.Serch-Con {
    height:10vh;
    /* border: 1px solid blue; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.Serch-Icon {
    /* border: 1px solid green; */
    display: inline-block;
}

.Serch-Icon svg {
    font-size: 20px;
    margin: 20px 20px 20px 20px;
}

.Search-Data-Con{
    /* border: 1px solid red; */
    display: inline-block;
    padding: 0px 20px;
    margin: 30px 0px;
    /* background-color: whitesmoke; */
    border-radius: 50px;
    box-shadow: 0 0 5px gray;
}
@media(min-width:150px) and (max-width:500px)
{
    .Search-Data-Con{
        /* border: 1px solid red; */
        display: block;
        padding: 0px 20px;
        margin: 30px 0px;
        /* background-color: whitesmoke; */
        border-radius: 50px;
        box-shadow: 0 0 5px gray;
    }

}




.Search-input input {
    margin: 22px 20px 22px 20px;
    width: 70vh;
    border: none;
    /* background-color: whitesmoke; */

}
@media(min-width:150px) and (max-width:500px)
{
    .Search-input input {
        margin: 22px 20px 22px 20px;
        width: 10vh;
        border: none;
        /* background-color: whitesmoke; */
    
    }
}


/* input[type="text" i] {
    padding: 1px 2px;
} */

:focus-visible {
    border: none;
    outline: none !important;
    /* outline: -webkit-focus-ring-color auto 0px !important; */
}




/* ------------------------new product page --------------------- */
.shell{
    padding:80px 0;
  }
  .wsk-cp-product{
    background:#fff;
    padding:15px;
    border-radius:6px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    position:relative;
    margin:20px auto;
  }
  .wsk-cp-img{
    position:absolute;
    top:5px;
    left:50%;
    transform:translate(-50%);
    -webkit-transform:translate(-50%);
    -ms-transform:translate(-50%);
    -moz-transform:translate(-50%);
    -o-transform:translate(-50%);
    -khtml-transform:translate(-50%);
    width: 100%;
    padding: 15px;
    height:250px;
    transition: all 0.2s ease-in-out;
  }
  .wsk-cp-img img{
    width:100%;
    transition: all 0.2s ease-in-out;
    border-radius:6px;
    height:100%
  }
  /* .wsk-cp-product:hover .wsk-cp-img{
    top:-40px;
  }
  .wsk-cp-product:hover .wsk-cp-img img{
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  } */
  .wsk-cp-text{
    padding-top:90%;
  }
  .wsk-cp-text .category{
    text-align:center;
    font-size:12px;
    font-weight:bold;
    padding:5px;
    margin-bottom:45px;
    position:relative;
    transition: all 0.2s ease-in-out;
  }
  .wsk-cp-text .category > *{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    -khtml-transform: translate(-50%,-50%);
      
  }
  .wsk-cp-text .category > span{
    padding: 12px 30px;
    border: 1px solid #313131;
 
    background:#fff;
    color:#313131;
    box-shadow: 0 15px 28px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.10);
    border-radius:27px;
    transition: all 0.05s ease-in-out;
    width:150px;
    height:50px;
    
  }
  .wsk-cp-product:hover .wsk-cp-text .category > span{
    border-color:#ddd;
    box-shadow: none;
    padding: 11px 28px;
  }
  .wsk-cp-product:hover .wsk-cp-text .category{
    margin-top: 0px;
  }
  .wsk-cp-text .title-product{
    text-align:center;
  }
  .wsk-cp-text .title-product h3{
    font-size:18px;
    font-weight:bold;
    margin:15px auto;
    overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    width:60%;
  }
  .wsk-cp-text .description-prod p{
    margin:0;
  }
  .wsk-cp-img .swiper-pagination {
	position: absolute;
	/* top: 200px; */
  bottom:6px;
	right: 100px;
  background:#fff;
  border-radius: 5px;;
	width: auto !important;
	left: auto !important;
	margin: 0;
}
.wsk-cp-img .swiper-button-next:after, .wsk-cp-img .swiper-button-prev:after{
  font-size: 14px;
  background-color: #eb6e1d;
  padding: 3px 7px;
  border-radius: 20px;
  color: black;
}
.wsk-cp-img .swiper-pagination-bullet {
  padding: 5px 5px;
  border-radius: 20px;
  width: auto;
  height: 11px;
  text-align: center;
  line-height: 29px;
  font-size: 5px;
  /* color: #000; */
  opacity: 1;
  /* background: rgba(0,0,0,0.2); */
}
.wsk-cp-img .swiper-pagination-bullet-active {
	color:#fff;
	background: #007aff;
}

  /* Truncate */
  .wsk-cp-text .description-prod {
    text-align:center;
    width: 100%;
    height:62px;
    overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    margin-bottom:15px;
  }
  .card-footer{
    padding: 25px 0 5px;
    border-top: 1px solid #ddd;
  }
  .card-footer:after, .card-footer:before{
    content:'';
    display:table;
  }
  .card-footer:after{
    clear:both;
  }
  
  .card-footer .wcf-left{
    float:left;
    
  }
  
  .card-footer .wcf-right{
    float:right;
  }
  
  .price{
    font-size:18px;
    font-weight:bold;
  }
  
  a.buy-btn{
    display:block;
    color:#212121;
    text-align:center;
    font-size: 18px;
    width:35px;
    height:35px;
    line-height:35px;
    border-radius:50%;
    border:1px solid #212121;
    transition: all 0.2s ease-in-out;
  }
  a.buy-btn:hover , a.buy-btn:active, a.buy-btn:focus{
    border-color: #FF9800;
    background: #FF9800;
    color: #fff;
    text-decoration:none;
  }
  .wsk-btn{
    display:inline-block;
    color:#212121;
    text-align:center;
    font-size: 18px;
    transition: all 0.2s ease-in-out;
    border-color: #FF9800;
    background: #FF9800;
    padding:12px 30px;
    border-radius:27px;
    margin: 0 5px;
  }
  .wsk-btn:hover, .wsk-btn:focus, .wsk-btn:active{
    text-decoration:none;
    color:#fff;
  }  
  .red{
    color:#F44336;
    font-size:22px;
    display:inline-block;
    margin: 0 5px;
  }
@media(max-width:999px) and  (min-width:750px){
    .wsk-cp-img{
      position:absolute;
      top:5px;
      left:50%;
      transform:translate(-50%);
      -webkit-transform:translate(-50%);
      -ms-transform:translate(-50%);
      -moz-transform:translate(-50%);
      -o-transform:translate(-50%);
      -khtml-transform:translate(-50%);
      width: 100%;
      padding: 15px;
      height:250px;
      transition: all 0.2s ease-in-out;
    }
    .wsk-cp-img img{
      width:100%;
      transition: all 0.2s ease-in-out;
      border-radius:6px;
      height:100%
    }
    .wsk-cp-product:hover .wsk-cp-img{
      top:-40px;
    }
    .wsk-cp-product:hover .wsk-cp-img img{
      box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    }
    .wsk-cp-text{
      padding-top:80%;
    }
    .wsk-cp-product{
      margin:40px auto;
    }
    .wsk-cp-product .wsk-cp-img{
    top:-40px;
  }
  .wsk-cp-product .wsk-cp-img img{
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  }
    .wsk-cp-product .wsk-cp-text .category > span{
    border-color:#ddd;
    box-shadow: none;
    padding: 11px 28px;
  }
  .wsk-cp-product .wsk-cp-text .category{
    margin-top: 0px;
  }
  a.buy-btn{
    border-color: #FF9800;
    background: #FF9800;
    color: #fff;
  }
  }
  @media(max-width:700px) {
    .wsk-cp-img{
      position:absolute;
      top:5px;
      left:50%;
      transform:translate(-50%);
      -webkit-transform:translate(-50%);
      -ms-transform:translate(-50%);
      -moz-transform:translate(-50%);
      -o-transform:translate(-50%);
      -khtml-transform:translate(-50%);
      width: 100%;
      padding: 15px;
      height:250px;
      transition: all 0.2s ease-in-out;
    }
    .wsk-cp-img img{
      width:100%;
      transition: all 0.2s ease-in-out;
      border-radius:6px;
      height:100%
    }
    .wsk-cp-product:hover .wsk-cp-img{
      top:-40px;
    }
    .wsk-cp-product:hover .wsk-cp-img img{
      box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    }
    .wsk-cp-text{
      padding-top:80%;
    }
    .wsk-cp-product{
      margin:40px auto;
    }
    .wsk-cp-product .wsk-cp-img{
    top:-40px;
  }
  .wsk-cp-product .wsk-cp-img img{
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  }
    .wsk-cp-product .wsk-cp-text .category > span{
    border-color:#ddd;
    box-shadow: none;
    padding: 11px 28px;
  }
  .wsk-cp-product .wsk-cp-text .category{
    margin-top: 0px;
  }
  a.buy-btn{
    border-color: #FF9800;
    background: #FF9800;
    color: #fff;
  }
  }
  @media (max-width: 650px) {
    .wsk-cp-img{
      position:absolute;
      top:5px;
      left:50%;
      transform:translate(-50%);
      -webkit-transform:translate(-50%);
      -ms-transform:translate(-50%);
      -moz-transform:translate(-50%);
      -o-transform:translate(-50%);
      -khtml-transform:translate(-50%);
      width: 100%;
      padding: 15px;
      height:200px;
      transition: all 0.2s ease-in-out;
    }
    .wsk-cp-img img{
      width:100%;
      transition: all 0.2s ease-in-out;
      border-radius:6px;
      height:100%
    }
    .wsk-cp-product:hover .wsk-cp-img{
      top:-40px;
    }
    .wsk-cp-product:hover .wsk-cp-img img{
      box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    }
    .wsk-cp-text{
      padding-top:90%;
    }
    .wsk-cp-product{
      margin:40px auto;
    }
    .wsk-cp-product .wsk-cp-img{
    top:-40px;
  }
  .wsk-cp-product .wsk-cp-img img{
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  }
    .wsk-cp-product .wsk-cp-text .category > span{
    border-color:#ddd;
    box-shadow: none;
    padding: 11px 28px;
  }
  .wsk-cp-product .wsk-cp-text .category{
    margin-top: 0px;
  }
  a.buy-btn{
    border-color: #FF9800;
    background: #FF9800;
    color: #fff;
  }
  }
  @media(max-width: 500px) {
    .wsk-cp-img{
      position:absolute;
      top:5px;
      left:50%;
      transform:translate(-50%);
      -webkit-transform:translate(-50%);
      -ms-transform:translate(-50%);
      -moz-transform:translate(-50%);
      -o-transform:translate(-50%);
      -khtml-transform:translate(-50%);
      width: 100%;
      padding: 15px;
      height:400px;
      transition: all 0.2s ease-in-out;
    }
    .wsk-cp-img img{
      width:100%;
      transition: all 0.2s ease-in-out;
      border-radius:6px;
      height:100%
    }
    .wsk-cp-product:hover .wsk-cp-img{
      top:-40px;
    }
    .wsk-cp-product:hover .wsk-cp-img img{
      box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    }
    .wsk-cp-text{
      padding-top:90%;
    }
    .wsk-cp-product{
      margin:40px auto;
    }
    .wsk-cp-product .wsk-cp-img{
    top:-40px;
  }
  .wsk-cp-product .wsk-cp-img img{
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  }
    .wsk-cp-product .wsk-cp-text .category > span{
    border-color:#ddd;
    box-shadow: none;
    padding: 11px 28px;
  }
  .wsk-cp-product .wsk-cp-text .category{
    margin-top: 0px;
  }
  a.buy-btn{
    border-color: #FF9800;
    background: #FF9800;
    color: #fff;
  }
  }

  .search-box
  {
    display:flex;
    /* justify-content: center;
    align-items: center; */
    /* flex-direction: column; */
  }
  
  .search-box input
  {
   border:1px solid blue;
   height: 50px;
   width:500px;

  }
  .search_btn
  {
    width:120px;
    height:50px;
    background:#7b7c7e;
    color:#fff;
    border:none;
  }
  .search_btn i
  {
    font-size:22px;
    font-weight:800;

  }
  .count h2{
    font-size:16px;
    padding:10px;
    border:1px solid blue;
    /* width: 500px; */
  }
  .count input{
    width:200px;
    margin-left:10px;
  }


  .formTitleSection
  {
    display:flex;
    justify-content: end;
  }
  .closeProgram1 
  {
    width:30px;
    height:30px;
   display:flex;
   justify-content:center;
   align-items:center;
    
    border-radius:5px;
    background:#c0c0c0;
    
  }
  .closeProgram1 i{
    display:flex;
   justify-content:center;
   align-items:center;
    font-size:20px;
    color:#fff;
  }
  /* ============================================ Equary Modal ============================== */
#partner {
  width: 100%;
  z-index: 1000;
  flex-direction: column;
}
.programButton {
  overflow: hidden;
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}

.partnerProgramForm {
  padding: 20px;
  display: flex;
  justify-content:end;
  /* align-items: center; */
  flex-direction: column;
  position: relative;
  z-index: 1000;
  margin: auto;
  border-radius: 10px;
  cursor: pointer;
  animation: uparrow 0.6s infinite alternate ease-in-out;
}

.partnerProgramForm img{
  width: 100%;
  height:100%;
}
