@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap");

/* ////////////////////////// Section 1 //////////////////////////// */

.Service-Section1 {
  /* border: 1px solid red; */
  height: 100vh;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.4)),
    url("../Images/Service\ back2.jpg");
  background-size: cover;
  background-position: center;
}

.Service-Section-Text {
  /* border: 1px solid blue; */
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.Service-Section-Text h1 {
  text-transform: uppercase;
  font-family: var(---FontThird);
  letter-spacing: 1px;
  color: whitesmoke;
}
.Service-Section-Text p {
  max-width: 660px;
  margin: 10px 0;
  font-size: 1rem;
  text-transform: uppercase;
  font-family: var(---FontThird);
  letter-spacing: 1px;
  color: whitesmoke;
}

/* ///////////////////////// Section 2 //////////////////////////// */

.Service-Section2 {
  /* border: 1px solid red; */
  padding-top: 10vh;
  padding-bottom: 0vh;
}

.Services-Page-Images-con {
  /* border: 1px solid royalblue; */
  height: 70%;
  position: relative;
}

.ServiceS1-Overlay {
  position: absolute;
  bottom: -45px;
  right: 0;
  /* border: 1px solid red; */
  height: 45vh;
  width: 47vh;
  border: 10px solid white;
}

/* .Services1-Overlay-Ex {
    position: absolute;
    top: 0;
    left: 0; */
/* border: 1px solid red; */
/* display: inline-block;
    padding: 25px;
    margin: 15px;
    background-color: white;
}

.Services1-Overlay-Ex h2 {
    color: #901027;
    font-size: 45px;
    font-weight: 700;
    font-family: 'Fjalla One', sans-serif;
}

.Services1-Overlay-Ex h4 {
    color: #013A71;
    font-family: "Fjalla One", Sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 2em;
} */

.ServiceS1-Overlay img {
  height: 100%;
  width: 100%;
}

.Services-Page-Images {
  /* border: 1px solid saddlebrown; */
  margin: 0px 65px 0px 0px;
  height: 100%;
  width: 100%;
}

.Services-Page-Images img {
  height: 100%;
  width: 100%;
  border-radius: 2px;
}

.Service-Page-Advantage {
  /* border: 1px solid rebeccapurple; */
  text-align: left;
}

.Service-Page-Advantage-details {
  /* border: 1px solid chartreuse; */
  padding: 20px;
}
.advantage-Logo-con {
  /* border: 1px solid green; */
  height: 100%;
  display: flex;
  justify-content: left;
  margin-left: 62px;
}

@media (min-width: 570px) and (max-width: 699px) {
  .Service-Page-Advantage-details {
    /* border: 1px solid chartreuse; */
    padding: 40px 0;
  }
}
.Service-Page-Advantage h2 {
  font-family: var(---FontThird);
  font-size: 32px;
  margin-left: 40px;
}
@media (min-width: 150px) and (max-width: 699px) {
  .Service-Section1 {
    /* border: 1px solid red; */
    height: 60vh;
  }
  .Service-Section-Text h1 {
    font-size: 1.4rem;
    margin: 10px 6px;
    text-align: center;
    color: whitesmoke;
  }
  .Service-Section-Text p {
    font-size: 0.8rem;
    max-width: 660px;
    margin: 10px 10px;
  }
  .advantage-Logo-con {
    /* border: 1px solid green; */
    height: 100%;
    display: flex;
    justify-content: left;
    margin-left: 0px;
  }
  .Service-Page-Advantage {
  /* border: 1px solid rebeccapurple; */
  text-align: center;
}
.Services-Page-Images-con {
  /* border: 1px solid royalblue; */
  height: 100%;
  max-height: 40vh;
  position: relative;
}
}
.Advantage-con {
  margin-top: 20px;
  /* border: 1px solid blue; */
}

.advantage-Logo {
  /* border: 1px solid red; */
  height: 11vh;
  width: 11vh;
  padding: 15px;
  background-color: var(---TheamColor);
  border-radius: 50px;
}

.advantage-Logo img {
  height: 100%;
  width: 100%;
}

.Advantage-details {
  text-align: left;
}

.Advantage-details h4 {
  font-family: var(---FontFour);
  font-weight: 600;
  margin: 0px;
  text-transform: capitalize;
}

.Advantage-details p {
  font-size: 14px;
  margin: 0px;
}

/* ////////////////////////////////// Section 3 //////////////////////////////////////// */

.Service-Section3 {
  /* border: 1px solid red; */
  background-color: whitesmoke;
  padding-top: 10vh;
  padding-bottom: 10vh;
}

.Product-con {
  /* border: 1px solid blue; */
  box-shadow: 0 0 5px gray;
  border-radius: 2px;
  padding-bottom: 20px;
}

.Product-Image {
  /* border: 1px solid green; */
  height: 35vh;
  border-radius: 2px;
}

.Product-Image img {
  height: 100%;
  width: 100%;
  border-radius: 2px;
}

.Product-Name {
  /* border: 1px solid red; */
  margin-top: 20px;
  margin-left: 10px;
}

.Product-Name h4 {
  /* text-align: left; */
  font-family: var(---FontFour);
  font-weight: 500;
  font-size: 25px;
}

.Product-morebtn {
  margin-top: 20px;
  /* text-align: left; */
  margin-left: 10px;
  position: relative;
  display: inline-block;
}

.Product-morebtn button {
  border: none;
  padding: 10px 40px;
}

.Product-morebtn button:before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  top: -5px;
  left: -5px;
  border-top: 2px solid tomato;
  border-left: 2px solid tomato;
  transition: all 0.25s;
}

.Product-morebtn button:after {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  bottom: -5px;
  right: -5px;
  border-bottom: 2px solid tomato;
  border-right: 2px solid tomato;
  transition: all 0.3s;
}

.Service-Section3-heading {
  /* border: 1px solid red; */
  padding: 20px 20px 40px 20px;
}

.Service-Section3-heading h2 {
  font-size: 35px;
  font-family: var(---FontThird);
}
