.nav-bar-component {
    position: absolute;
    top: 0;
    /* bottom: 0; */
    left: 0;
    right: 0;
    z-index: 99;
}


/* .NavSection {
    background-color: rgba(0, 0, 0, 0.20);
    background-color: #241b1b;
    
} */

/* TO MID ELEMENT CENTER  */

.justify-content-start {
    justify-content: center !important;
}

/* ///////////////////////////// */
.NavBar {
    /* border: 1px solid blue; */
    border-radius: 4px;
}

.NavBar-bg {
    /* background-color: rgb(22, 176, 248); */
    /* position: fixed; */
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
}

.nav-link a {
    padding-left: 12px;
    padding-right: 12px;
    text-decoration: none;
    color: white;
    /* color: whitesmoke; */
    font-family: var(---FontFirst);
    font-size: 18px;
    font-weight: 500;
}

.active {
    /* background-color: grey; */
    font-weight: bold;
    border-radius: 8px;
    border: 2px solid white;
}

.active a {
    color: 000;
}

.navbar-dark .navbar-nav .nav-link {
    text-align: left;
}

.Social-media-icons a{
    text-align: left;
    border: none !important;
}


@media(max-width:992px) {
    .Social-media-icons {
        display: none;
    }
}

.nav-link a:hover {
    color: white;
}
.NavBar-bg {
    background-color:#eb6e1d;
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    
}

/* ////////////////////////////////////////// NavBar-icons ///////////////////////////////////////////////// */

.Social-media-icons svg {
    color: white;
    /* color: whitesmoke; */
    font-size: 20px;
}

.Social-media-icons a:hover svg {
    color: white;
}

.Social-media-icons a {
    margin-left: 8px;
    color: white;
    font-size: 18px;
    margin-right: 8px;
}

.NavLogo {
    /* border: 1px solid red; */
    height: 12vh;
    width: 25vh;
    transform: scale(1.3);
}

.NavLogo img {
    height: 100%;
    width: 100%;
}
@media (max-width: 990px){
.navbar-collapse {
    background-color: #eb6e1d;
    padding-bottom: 20px;
}
.NavLogo {
    height: auto;
    transform: scale(1.5);
    /* transform: scale(.8); */
    width: 100px
}
}




/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */


.header-material {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}



.Email_Support {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    /* border: 1px solid green; */
}

.Call_Support {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    /* border: 1px solid saddlebrown; */
}

.Workinghr {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    /* border: 1px solid aqua;*/
}

.Appointment {
    /* border: 1px solid darkgreen; */
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.Appointment button {
    border-style: none;
    border-radius: 4px;
    background-color: #30d2b9;
    width: 175px;
    color: white;
}

.Appointment:hover button {
    color: rgb(0, 8, 255);
}



.header-material {
    width: 100%;
    height: 5rem;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
}


.Email-logo {
    font-size: 30px;
    padding: 10px;
    margin-bottom: 15px;
    color: #30d2b9;
}

.Email-text {
    font-size: 15px;
}

.Email-text span {
    color: rgb(84, 84, 84);
}

.Email-text h5 {
    font-size: 17px;
    color: rgb(117, 117, 117);
}

.Call-logo {
    font-size: 30px;
    padding: 10px;
    margin-bottom: 15px;
    color: #30d2b9;
}

.Call-text {
    font-size: 15px;
}

.Call-text span {
    color: rgb(84, 84, 84);
}

.Call-text h5 {
    font-size: 17px;
    color: rgb(117, 117, 117);
}

.Time-logo {
    font-size: 30px;
    padding: 10px;
    margin-bottom: 15px;
    color: #30d2b9;
}

.Time-Text {
    font-size: 15px;
}

.Time-Text span {
    color: rgb(84, 84, 84);
}

.Time-Text h5 {
    color: rgb(117, 117, 117);
    font-size: 17px;
}

@media(max-width:992px) {
    .header {
        display: none;
    }
}


@media(max-width:430px) {
    .FooterLogo {
        /* margin-left: 14vh; */
        height: 10vh;
        width: 25vh;
    }
}