.Footer-Section {
    /* border: 1px solid red; */
    padding-top: 10vh;
    padding-bottom: 5vh;
    background-color: #241b1b;
    /* background: linear-gradient(112.1deg, rgba(236, 126, 16, 0.5) 11.4%,  rgba(236, 126, 16, 0.5) 11.4%),url('../Images/footerbg.jpg'); */
}

.Footer-Image {
    /* border: 1px solid blue; */
    height: 18vh;
    width: 33vh;
    margin: 0px 10px 10px 0px;
}

.Footer-Image img {
    height: 100%;
    width: 100%;
}


.Footer-Logo-con p {
    text-align: left;
    font-family: var(---ParaFont);
    font-size: 13px;
    color: whitesmoke;
}



.nav_menu-2 {
    font-size: 16px;
    /* color: black; */
    line-height: 1.75em;
    padding-left: 120px;
    text-align: left;
}

@media(min-width:570px) and (max-width:699px)
{
    .nav_menu-2 {
        font-size: 16px;
        /* color: black; */
        line-height: 1.75em;
        padding-left: 0;
        text-align: left;
    }
}
@media(min-width:150px) and (max-width:500px)
{
    .nav_menu-2 {
        font-size: 16px;
        /* color: black; */
        line-height: 1.75em;
        padding-left: 0;
        text-align: left;
    }
}
.footer-title {
    font-size: 24px;
    /* color: black; */
    color: whitesmoke;
    margin-bottom: 20px;
    font-family: var(---HeadingFont);
    font-weight: 600;
}

/* .menu-footer-menu-container {} */

.menu-footer-menu {
    padding-left: 0;
    list-style: none;
    margin-top: 0;
    margin-bottom: 1rem;
}

.menu-item {
    text-decoration: none;
}

.menu-item a {
    position: relative;
    font-size: 16px;
    /* color: black; */
    color: whitesmoke;

    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    text-decoration: none;
}

.widget {
    /* border: 1px solid red; */
    text-align: left;
}

.widget h3 {
    font-size: 24px;
    /* color: black; */
    margin-bottom: 20px;
    font-family: var(---HeadingFont);
    font-weight: 600;
    color: whitesmoke;
}



.footer-contact-text {
    /* border: 1px solid black; */
    font-size: 14px;
    /* color: black; */
    color: whitesmoke;
    font-family: var(---ParaFont);
    line-height: 22px;
}





.ti-headphone-alt {
    /* border: 1px solid red; */
    height: 20px;
}

.ti-headphone-alt svg {
    font-size: 20px;
    margin: 0px 15px 0px 0px;
    color: whitesmoke;
}

.footer-contact-phone a {
    font-family: var(---ParaFont);
    color: whitesmoke !important;
}

.footer-contact-mail {
    font-size: 16px;
    line-height: 2em;
    position: relative;
    display: inline-block;
    /* -webkit-transition: all 500ms ease; */
    transition: all 500ms ease;
    color: whitesmoke;
}


.footer-contact-mail a{
    font-size: 16px;
    line-height: 2em;
    /* text-decoration: none; */
    transition: all 500ms ease;
    color: whitesmoke;
}

/* .footer-contact-mail:before {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #748173;
    content: "";
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
} */


.Footer-Social-Media svg {
    color: whitesmoke;
    font-size: 20px;
    margin: 5px 5px;
}

.Copy-Right {
    margin-top: 30px;
    display: flex;
}

.Contact-Us-Copy {
    /* border: 1px solid hotpink; */
    width: 50%;
    text-align: left;
    color:#fff;
    margin-right:10px;

}

.credits {
    color:#fff;
    margin-right:10px;
    /* border: 1px solid navajowhite; */
    width: 50%;
    text-align: right;
}

@media(max-width:765px) {
    .Copy-Right {
        display: block;
    }

    .Contact-Us-Copy {
        width: auto;
    }
    .credits {
        width: auto;
        text-align: left;
    }
}