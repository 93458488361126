HomeSectionBanne/* ////////////////////// Section 1 Banner /////////////////////// */


.HomeSectionBanner {
    height: 100%;
    /* border: 1px solid blue; */
}

.BannerText-con {
    /* border: 1px solid blue; */
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.BannerText {
    /* border: 1px solid red; */
    width: 100%;
    margin-left: 10vh;
    text-align: center;
}

@media(min-width:150px) and (max-width:500px)
{
    .BannerText {
        /* border: 1px solid red; */
        width: 90vh;
        margin-left: 0;
        text-align: center;
    }
}
.BannerText h1 {
    font-family: var(---NewFonts);
    font-size: 35px;
    color: whitesmoke;
}
.BannerText h2{
    font-family: var(---NewFonts);
    font-size: 35px;
    color: whitesmoke;
}


.BannerText p {
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: var(---NewFonts);
    color: whitesmoke;
}



.Home-Banner-Btn button {
    /* border: 1px solid blue; */
    background-color: #eb6e1d;
    padding: 12px 25px;
    border-radius: 6px;
    color: whitesmoke;
    border: none;
}

.HomeSectionBanner .swiper {
    height: 100vh;
}
@media(min-width:150px) and (max-width:500px)
{
    .HomeSectionBanner .swiper {
        height: 60vh;
    } 
    .BannerText h1 {
        font-size: 15px;
    }
    .BannerText h2{
        font-size: 15px;
    }
    
.BannerText p {
    font-size: 18px;
}



.Home-Banner-Btn button {
    padding: 7px 8px;
}

}

.HomeBanner01 {
    height: 100%;
    background: linear-gradient(to right, rgb(0, 0, 0, .2), rgb(0, 0, 0, .2)), url("../Images/Axia\ banner\ 02.jpg");
    background-size: cover;
    background-position: center;
}

.HomeBanner02 {
    height: 100%;
    background: linear-gradient(to right, rgb(0, 0, 0, .2), rgb(0, 0, 0, .2)), url("../Images/Axia\ Banner\ 03.webp");
    background-size: cover;
    background-position: center;
}

.HomeBanner03 {
    height: 100%;
    background: linear-gradient(to right, rgb(0, 0, 0, .2), rgb(0, 0, 0, .2)), url("../Images/Axia\ Banner\ 04.jpg");
    background-size: cover;
    background-position: center;
}



/* ////////////////////// Section 2 AboutUs /////////////////////// */


.Home-Section-2 {
    padding: 10vh 0px;

    /* border: 1px solid black; */
}



.Home-AboutUs-details {
    height: 100%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.Home-AboutUs-detials-con {
    /* border: 1px solid blue; */
    text-align: left;
}

.Home-AboutUs-detials-con h6 {
    font-size: 20px;
    font-family: var(---FontThird);
    letter-spacing: 1px;
    font-weight: 500;
}

.Home-AboutUs-detials-con h2 {
    font-family: var(---FontThird);
    font-size: 35px;
    padding-bottom: 10px;
    margin: 0;
}
.Home-AddSection8{
    height:50vh;
}
.Home-AboutUs-detials-con h4 {
    text-transform: capitalize;
    font-family: var(---FontFour);
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 15px;
}

.Home-AboutUs-detials-con h4 span {
    margin-left: 7px;
    text-transform: capitalize;
    font-family: var(---styleFont);
}

.Home-AboutUs-detials-con p {
    margin-bottom: 30px;
}


.HomeAbout-ImageCon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}


.Home-About-Image {
    /* border: 1px solid green; */
    height: 55vh;
    width: 80vh;
    position: relative;
    padding: 0 15px 30px 30px;
}


@media(min-width:150px) and (max-width:500px)
{
    .HomeAbout-ImageCon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding:30px 0 0 0;
    }
    .Home-About-Image {
        /* border: 1px solid green; */
        height: 45vh;
        width: auto;
        position: relative;
        padding: 0 15px 30px 30px;
    }
    .Home-AboutUs-detials-con h2 {
        font-size: 33px;
   }    
   .Home-AboutUs-detials-con h4 {
    font-size: 28px;
}
.Home-Section-2 {
    padding: 0vh 0px;

    /* border: 1px solid black; */
}
.Home-AboutUs-details {
 margin-bottom: 20px;
}
.Lift-Boat-text-con{
    height: 69vh;
}
    
}
.Home-About-Image img {
    height: 100%;
    width: 100%;
    /* box-shadow: 0 0 5px red; */
}

.Home-About-Image:before {
    background-color: #eb6e1d;
    bottom: 0;
    content: "";
    left: 0px;
    position: absolute;
    right: 30px;
    top: 30px;
    z-index: -1;
}

.AboutUS-button {
    background-color: white;
}

.AboutUS-button button {
    border: none;
    background-color: offwhite;
    font-size: 16px;
    padding: 10px 30px;
    text-transform: capitalize;
    cursor: pointer;
    transform: all 0.3s;
    position: relative;
}

.AboutUS-button button:before {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    top: -5px;
    left: -5px;
    border-top: 2px solid tomato;
    border-left: 2px solid tomato;
    transition: all 0.25s;
}

.AboutUS-button button:hover:before,
.AboutUS-button button:hover:after {
    width: 107%;
    height: 121%;
}

.AboutUS-button button:after {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    bottom: -5px;
    right: -5px;
    border-bottom: 2px solid tomato;
    border-right: 2px solid tomato;
    transition: all 0.30s;
}


/* ///////////////////////////////// Section 3 ///////////////////////////// */

.HomeSection3 {
    /* border: 1px solid red; */
    padding-bottom: 10vh;
    padding-top: 5vh;
    background-color: whitesmoke;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, .6)), url("../Images/Banner\ Image\ 2.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.HomeSection-title {
    /* border: 1px solid blue; */
    font-family: var(---FontThird);
    margin: 30px;
}

.HomeSection-title h3 {
    font-family: var(---FontThird);
    font-size: 35px;
}

.HomeSection3-title h3 {
    font-family: var(---FontThird);
    font-size: 35px;
    color: whitesmoke;
}

.HomeSection-title-Para {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.HomeSection-title-Para p {
    width: 100vh;
}






.Card-Main-con:hover .Card-Back {
    transform: rotate(48deg);
}

.Card-Main-con:hover .Card-Name::before {
    width: 100%;
}

.Card-Body-con {
    /* border: 1px solid green; */
    margin: 20px;
}

.Card-logo-con {
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    position: relative;
}

.Card-Logo {
    /* border: 3px solid var(---TheamColorF); */
    height: 10vh;
    width: 10vh;
    padding: 10px;
    border-radius: 5px;
    /* background-color: var(---TheamColorS); */
    background-color: whitesmoke;
}

.Card-Back {
    position: absolute;
    top: 0;
    left: 90px;
    right: 0;
    bottom: 0;
    height: 13vh;
    width: 13vh;
    /* background-color:   ; */
    border-radius: 5px;
    z-index: -1;
    transition: all 1.5s;
}

.Card-Logo img {
    height: 100%;
    width: 100%;
}

.Card-Name {
    position: relative;
    display: inline-block;
    padding-bottom: 3px;
}

.Card-Name h4 {
    font-family: var(---FontThird);
    color: whitesmoke;
}

/* .Card-Name::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    height: 4px;
    background: var(---TheamColor);
    width: 100%;
    transition: .3s;
    border-radius: 5px;
    width: 40px;
    transition: all 1.5s;
} */


/* ///////////////////// */

/* .Card-Body-con {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    padding: 0px;
}

.card {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    padding: 0px;
    background-position: center;
    background-size: 100%;
    background-image: linear-gradient(55deg, #1a1a1a 20%, red 40%, purple 60%, #1a1a1a 80%);
    width: 250px;
    height: 350px;
    border-radius: 20px;
    background-size: 100%;
    transition: 1s;

}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    width: 240px;
    height: 340px;
    border-radius: 15px;
}

.content p {
    color: white;
    font-family: roboto, sans-serif;
    font-size: 40px;
}

.card:hover {
    background-position: center;
    background-size: 1000%;
    transition: 2s;
} */

/* ////////////////////////// */
/* 

@import url('https://fonts.googleapis.com/css2?family=Megrim&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.Card-Body-con {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-family: 'Megrim', cursive;
}

.card {
    width: 280px;
    height: 370px;
    margin: 30px 15px;
    border-radius: 15px;
    padding: 1.7em;
    position: relative;
    display: flex;
    align-items: flex-end;
    transition: transform 0.4s ease-out;
    box-shadow: 0px 7px 10px rgba(black, 0.5);
}

.card img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
}

.info-card {
    position: relative;
    z-index: 3;
    color: #fff;
    transform: translateY(50px);
    transition: transform 0.4s;
    opacity: 0;
}

.info-card p {
    letter-spacing: 1px;
    font-size: 15px;
    margin-top: 8px;
}

.card:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.7);
    transition: opacity 0.5s;
    border-radius: 15px;
    z-index: 2;
}

.card:hover {
    transform: translateY(20px);
}

.card:hover .info-card {
    opacity: 1;
    transform: translateY(0px);
}

.card:hover::before {
    opacity: 1;
} */


/* //////////////////////////// Section 4 ///////////////////////// */

.HomeSection4 {
    /* border: 1px solid red; */
    padding-top: 10vh;
    padding-bottom: 10vh;
    background-color: whitesmoke;
}


.HomeCard-2:hover .Homecard-2-text::before {
    width: 100%;
}

.HomeCard-2:hover .CardNumber {
    color: var(---TheamColor);
}

.HomeCard-2-con {
    /* border: 1px solid green; */
    position: relative;
    /* padding: 0px 20px 0px 0px; */
}

.HomeCard-2-Image {
    /* border: 1px solid violet; */
    height: 43vh;
    box-shadow: 0 0 5px grey;
}

.HomeCard-2-Image img {
    height: 100%;
    width: 100%;
}
@media(min-width:150px) and (max-width:500px)
{
    .HomeCard-2-Image {
        /* border: 1px solid violet; */
        height: 50vh;
        box-shadow: 0 0 5px grey;
    }
    
    .HomeCard-2-Image img {
        height: 100%;
        width: 100%;
    }
}

.CardNumber {
    /* border: 1px solid red; */
    font-size: 70px;
    font-family: var(---FontSecond);
    position: absolute;
    bottom: -50px;
    left: 10px;
    color: whitesmoke;
    transition: all 1.5s;
}

.HomeCard-2-Name {
    margin-top: 35px;
    text-align: left;
}

.HomeCard-2-Name h4 {
    text-transform: capitalize;
    font-family: var(---FontFour);
    padding: 5px 0px;
    font-size: 26px;
    font-weight: 600;
    display: inline-block;
    position: relative;
    transition: all 1.5s;
}

.Homecard-2-text::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    height: 4px;
    background: var(---TheamColor);
    width: 100%;
    transition: .3s;
    border-radius: 5px;
    width: 40px;
    transition: all 1.5s;
}


/* ///////////////////////// Section 5 ///////////////////////// */

.HomeSection5 {
    /* border: 1px solid red; */
    padding-top: 0vh;
    padding-bottom: 20vh;
}
@media(min-width:570px) and (max-width:699px)
{
    .HomeSection5 {
        /* border: 1px solid red; */
       padding:10px;
    }
    
}


/* HomeSection-5-Solution-con {} */


.HomeSection5-text::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    height: 4px;
    background: var(---TheamColor);
    border-radius: 5px;
    width: 40px;
    transition: all 1.5s;
}

.HomeSection-5-Solution-con h3 {
    padding-bottom: 5px;
    display: inline-block;
    font-family: var(---FontThird);
    font-size: 35px;
    position: relative;
}

.HomeSection-5-Solution-con p {
    font-family: var(---FontFour);
    font-size: 35px;
    font-weight: 500;
    text-align: left;
    /* margin-right: 238px; */
    margin-bottom: 15px;
    text-transform: capitalize;
}


.HomeSection5-points {
    /* border: 1px solid red; */
    margin-top: 10vh;
}

.Points-con {
    /* border: 1px solid blue; */
    height: 100%;
    box-shadow: 0 0 5px grey;
    padding: 15px;
    border-radius: 5px;
}

.Points-con:hover .Points-step-text::before {
    width: 100%;
}

.Points-con:hover .Points-Image-back {
    transform: rotate(48deg);
}

.point-con-Image-con {
    /* border: 1px solid green; */
    margin-bottom: 15px;
    position: relative;
}



.Points-Image {
    /* border: 1px solid hotpink; */
    background-color: var(---TheamColor);
    height: 13vh;
    width: 13vh;
    border-radius: 2px;
    padding: 15px;
}

.Points-Image-back {
    height: 13vh;
    width: 13vh;
    border-radius: 2px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: var(---TheamColor);
    transition: all 1.5s;
}

.Points-Image img {
    height: 100%;
    width: 100%;
    border-radius: 2px;
}

.Points-step {
    text-align: left;
    position: relative;
}

.Points-step h4 {
    text-transform: capitalize;
    font-family: var(---FontFour);
    padding: 5px 0px;
    font-size: 26px;
    font-weight: 600;
    display: inline-block;
    position: relative;
    transition: all 1.5s;
}



.Points-step-text::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    height: 4px;
    background: var(---TheamColor);
    width: 100%;
    transition: .3s;
    border-radius: 5px;
    width: 40px;
    transition: all 1.5s;
}

.Points-step p {
    font-size: 15px;
}


/* /////////////////////// Section 6 ////////////////////////////// */

.HomeSection6 {
    /* border: 1px solid red; */
    padding-bottom: 10vh;
}

.Home-Section6-title {
    text-align: left;
}

.Home-Section6-title h4 {
    position: relative;
    display: inline-block;
    padding-bottom: 5px;
    font-family: var(---FontThird);
    font-size: 35px;
}

.home-Section6-text::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    height: 4px;
    background: var(---TheamColor);
    width: 100%;
    transition: .3s;
    border-radius: 5px;
    width: 40px;
    transition: all 1.5s;
}

.Service-Card-Body {
    /* border: 1px solid red; */
    margin-top: 30px;
}

.Service-Card {
    /* border: 1px solid blue; */
    position: relative;
}

.Service-Card-Image {
    /* border: 1px solid seagreen; */
    height: 50vh;
    margin: 0px;
    overflow: hidden;
}

.Service-Card-Image img {
    height: 100%;
    width: 100%;
}

@media(min-width:150px) and (max-width:500px)
{
    .Service-Card {
        /* border: 1px solid blue; */
        position: relative;
    }
    
    .Service-Card-Image {
        /* border: 1px solid seagreen; */
        height: 30vh;
        margin: 0px;
        overflow: hidden;
    }
    
    .Service-Card-Image img {
        height: 100%;
        width: 100%;
    }
    .HomeSection-5-Solution-con p {
        font-size: 25px;
    }
}

.Services-Card-Overlay {
    position: absolute;
    /* margin: 0px 20px; */
    padding: 20px;
    /* border: 1px solid black; */
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom, transparent 0, rgba(0, 0, 0, .01) 2%, rgba(0, 0, 0, .75) 90%)
}

.Profile-Card-Name {
    font-size: 20px;
    /* font-family: ; */
    font-family: var(---NewFonts);
    font-weight: 400;
    color: whitesmoke;
    margin-bottom: 15px;
    line-height: 1.25em;
    text-align: left;
}

.Card-Name span {
    color: whitesmoke;
    font-family: var(---FontFour);
    letter-spacing: 0px;
    font-size: 20px;
    padding-left: 20px;
}


.Show-Line {
    display: flex;
    justify-content: left;
}

.Show-Line span {
    text-align: center;
    height: 1px;
    width: 60px;
    background-color: rgba(255, 255, 255, 0.2);
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    margin: auto 0 0px;
}

.Service-Card:hover .Service-Card-Image img {
    -webkit-filter: none;
    filter: none;
    -webkit-transform: scale(1.09, 1.09);
    transform: scale(1.09, 1.09);
    -webkit-filter: brightness(70%);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}


.Service-Card:hover .Show-Line span {
    width: 100%;
    transition-property: all;
    transition-duration: 0.8s;
    transition-timing-function: ease-in-out;
}

@media(max-width:900px) {
    .Service-Card-Body {
        margin: 20px;
    }

    .Service-Card-Image {
        height: 50vh;
    }
}

/* ///////////////////////////////// Section 7 ///////////////////////////// */

.HomeSection7 {
    /* border: 1px solid red; */
    padding-top: 10vh;
    padding-bottom: 10vh;
    background-color: whitesmoke;
}



/* //// */

.Key-Con {
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh;
}

.Key-Con-Image {
    /* border: 1px solid green; */
    height: auto;
    width: 150px;
    object-fit: contain;
}

.Key-Con-Image img {
    height: 100%;
    width: 100%;
}

.Key-Con-two {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    height: 20vh;
}

.Key-Con-Image-second {
    /* border: 1px solid blue; */
    height: 10vh;
    width: 30vh;
}

.Key-Con-Image-second img {
    height: 100%;
    width: 100%;
}

/* //////////////////////// Added Section 8 //////////////////////// */


.Lifts-Section {
    /* border: 1px solid blue; */
    height: 100vh;
    background: url("../Images/containers-port-jpg.jpeg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.Lift-Section-Details {
    /* border: 1px solid red; */
    height: 100vh;
    width: 50%;
}

@media(min-width:150px) and (max-width:500px)
{
    .Lifts-Section {
        /* border: 1px solid blue; */
        height: 69vh;
        background: url("../Images/containers-port-jpg.jpeg");
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
    }
    .Lift-Section-Details {
        /* border: 1px solid red; */
        height: 69vh;
        width: 50%;
    }
}
.Lift-Section-Details-con {
    /* border: 1px solid green; */
    display: flex;
    justify-content: end;
    align-items: center;
}

.Lift-Boat-text-con {
    /* border: 1px solid aqua; */
    background: linear-gradient(90deg, rgba(168, 147, 147, 0.8), rgba(255, 255, 255, 0.8));
    height: 69vh;
    display: flex;
    justify-content: center;
    align-items: center;
}


@media(max-width:150px) and(min-width:500px)
{
    .Lifts-Section {
        /* border: 1px solid blue; */
        height: 50vh;
        background: url("../Images/containers-port-jpg.jpeg");
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
    }
    .Lift-Boat-text-con {
        /* border: 1px solid aqua; */
        background: linear-gradient(90deg, rgba(168, 147, 147, 0.8), rgba(255, 255, 255, 0.8));
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
}

.anchor-Logo-Con {
    /* border: 1px solid greenyellow; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.anchor-Logo {
    /* border: 1px solid fuchsia; */
    height: 10vh;
    width: 10vh;
}

.anchor-Logo img {
    height: 100%;
    width: 100%;
}

.LiftBoat-mid-con h2 {
    /* color: white; */
    font-family: var(---FontThird);
    font-size: 50px;
    margin: 20px 0px;
    letter-spacing: 1px;
}
@media(min-width:150px) and (max-width:500px)
{
    .LiftBoat-mid-con h2 {
        /* color: white; */
        font-family: var(---FontThird);
        font-size: 30px;
        margin: 20px 0px;
        letter-spacing: 1px;
    } 
}
.LiftBoat-mid-con h3 {
    /* color: white; */
    letter-spacing: 3px;
    font-family: var(---FontFour);
}

/* ////////////////////////// Section 8 Pai chart /////////////////////////// */

.Home-Section8 {
    /* border: 1px solid red; */
    padding: 20vh 0px;

}


/* //////////////////// Card //////////////////////// */

.cards {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media(min-width:570px) and (max-width:699px)
{
    .cards {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top:30px;
    }
}

@media(min-width:150px) and (max-width:500px)
{
    .cards {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top:30px;
    }
}

.cards .card .close:hover {
    background-size: 100%;
    opacity: 0.8;
}

.about:hover .social.linkedin .icon:hover {
    background-color: #0077b5;
}

.cards .card .actions .btn:hover {
    background-color: rgba(255, 255, 255, 0.5);
}

.cards .card .actions .btn:hover span {
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.cards .card .actions .btn:hover .icon {
    width: 22px;
    opacity: 1;
    transition: all 0.4s ease-in-out;
}

.cards .card .actions .btn:hover:nth-child(3) .icon {
    width: 18px;
}

.cards .card .actions .btn:hover:before {
    height: 100%;
}

.card {
    height: 440px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 20px 30px -10px rgba(0, 0, 0, 0.1);
    /* max-width: 300px;
    min-width: 260px; */
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: relative;
    transition: all 0.4s ease;
    margin: 0 10px;
}

.card:before {
    height: 190px;
    width: calc(100% + 100px);
    content: "";
    position: absolute;
    background-image: linear-gradient(to top, #e0a57f 0%, #ff7f2b 100%);
    border-radius: 4px 4px 100% 100%;
    transition: all 0.4s ease-out;
    top: 0;
}
@media(min-width:150px) and (max-width:500px)
{
    .card:before {
        height: 150px;
        width: calc(100% + 100px);
        content: "";
        position: absolute;
        background-image: linear-gradient(to top, #e0a57f 0%, #ff7f2b 100%);
        border-radius: 4px 4px 100% 100%;
        transition: all 0.4s ease-out;
        top: 0;
    }
}
.close {
    width: 18px;
    height: 18px;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    background-image: url("https://rafaelalvucas.github.io/assets/icons/c/cross.svg");
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
    top: 0;
    right: 0;
    margin: 10px;
    padding: 5px;
    transition: all 0.2s ease;
}

.close:hover {
    background-size: 100%;
    opacity: 0.8;
}


.arrow {
    display: none;
}

article {
    z-index: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.card h2 {
    color: white;
    margin: 0;
    padding: 40px 20px 10px 20px;
    font-weight: 500;
    font-size: 22px;
    letter-spacing: 0.5px;
    height: 120px;
    font-family: var(---NewFonts);
}

.title {
    color: white;
    padding: 10px 20px;
    letter-spacing: 0.8px;
    transition: all 0.4s ease;
}

.desc {
    padding: 10px 30px;
    font-size: 14px;
    text-align: center;
    line-height: 24px;
    color: #666;
    height: 90px;
    transition: all 0.4s ease-out;
}

.pic {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 100%;
    margin: 20px 0;
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.3);
    transition: all 0.6s ease;
    padding: 25px;
    background-color: white;

}

.pic img {
    width: 100%;
    /* -webkit-filter: grayscale(100%); */
    /* filter: grayscale(100%); */
}
@media(min-width:150px) and (max-width:500px)
{
    .desc {
        padding: 10px;
        font-size: 14px;
        text-align: center;
        line-height: 24px;
        color: #666;
        overflow-y:scroll;
        height: 90px;
        transition: all 0.4s ease-out;
    }
}




/*  */ 


.cards_section{
    padding: 10px 10px;
}
.servi_card{
    /* border: 1px solid #eb6e1d; */
    border-radius: 20px;
    height:40vh;
    padding: 7px;
    width: 100%;
}

.servi_card-1 {
    margin: 20px 0px 10px 10px;
}
.servi-card-img{
    /* max-width: 250px; */
    width: 100%;
    height: 100%;
    /* padding: 10px 10px; */
}

.servi-card-img img{
    max-height: 150px;
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.serv_card-title h5{
    margin: 0;
    /* font-weight: 500; */
    font-size: 25px;
    text-align: left;
    letter-spacing: 0.5px;
    font-family: var(---FontThird);
}
.serv_card-desc{
text-align: left;
padding: 10px;
font-size: 18px;
text-align: left;
line-height: 24px;
color: #666;
height: 65px;
transition: all 0.4s ease-out;
}



@media(min-width:150px) and (max-width:500px)
{
    .servi-card-img{
        max-width: 270px;
        width: 100%;
        max-height: 134px;
        height: 100%;
        padding: 8px 0px;
    }
    
    .servi-card-img img{
        width: 100%;
        height: 100%;
    }
    .servi_card{
        margin-bottom: 20px;
    }
    .serv_card-desc{
text-align: left;
padding: 10px;
font-size: 15px;
text-align: left;
line-height: 24px;
color: #666;
height: 65px;
transition: all 0.4s ease-out;
}
}


@media(min-width:501px) and (max-width:1400px)
{
    .servi_card{
        margin-bottom: 20px;
    }
}
@media(min-width:501px) and (max-width:930px){
    .serv_card-title h5{
        margin: 0;
        /* font-weight: 500; */
        font-size: 11px;
        text-align: left;
        letter-spacing: 0.5px;
        font-family: var(---NewFonts);
    }
}


.Home-Section-7-Swiper .swiper-button-next:after, .Home-Section-7-Swiper .swiper-button-prev:after{
    font-size: 15px;
    /* background-color: #eb6e1d; */
    padding: 3px 7px;
    color: black;
  }