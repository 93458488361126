.errorbanner{
    background-color:#eb6e1d;
    height:100px;
    margin-bottom:30px;
  }
  .errorlogo {
      width: 100%;
      height: 100%;
    }
    .errorlogo img {
      width: 90%;
      height: 90%;
    }
    
    .ErrorDesc {
      padding: 20px 0px;
    }
    .ErrorDesc h2 {
      font-size: 60px;
      color:#eb6e1d;
      margin-bottom:20px;
    }
    .ErrorDesc span {
      color:black;
    }
    .ErrorDesc p {
      color: #808080;
      margin-bottom:20px;
    }
    .ErrorDesc a {
      padding: 10px 30px;
      border-radius: 50px;
      cursor: pointer;
      z-index: 3;
      color:#eb6e1d;
      border: 1px solid #eb6e1d;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 16px;
      transition: all 0.3s ease;
      text-decoration: none;
    }
    
    .ErrorDesc a:hover {
      box-shadow: 0px 10px 10px -10px rgba(54, 24, 79, 0.5);
      transform: translateY(5px);
      background:#eb6e1d;
      color: white;
    }
    .ErrorRight {
      display: flex;
      justify-content: center;
      align-items: center;
      margin:60px;
    }
    .errorImg{
        width: 100%;
        height: 100%;
    }
    
    .errorImg img{
        width: 100%;
        height: 100%;
        max-width: 450px;
        max-height: 450px;
    }
    @media(max-width:600px){
        .ErrorDesc h2 {
            font-size: 30px;
            color:#eb6e1d;
          }
        .ErrorRight {
            display: flex;
            justify-content: center;
            align-items: center;
            margin:30px;
          }
          .ErrorDesc a {
            padding: 7px 20px;
          }
      }